import Vue from "vue";
import Vuex from "vuex";
import { wallet } from "@/store/modules/wallet";
import { layout } from "@/store/modules/layout";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    wallet,
    layout,
  },
});
