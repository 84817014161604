import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyC3wm2bQMANImY_oq_yfMdsgcTy4Nbbuhg",
    authDomain: "nft-game-ecd4a.firebaseapp.com",
    projectId: "nft-game-ecd4a",
    storageBucket: "nft-game-ecd4a.appspot.com",
    messagingSenderId: "326080973327",
    appId: "1:326080973327:web:3f7630f4fb83a991bc717a",
    measurementId: "G-PKG9LMEZ9P"
  };
  const app = initializeApp(firebaseConfig);
  
  export const db = getFirestore(app);