<template>
  <div id="app">
    <router-view />
    <WalletPanel ref="wallet-panel" class="wallet-panel" />
    <button @click="openWallet" class="wallet-drawer-btn">Wallet</button>
  </div>
</template>




<script>
import WalletPanel from "@/components/layout/WalletPanel";
export default {
  components: {
    WalletPanel,
  },
  methods: {
    openWallet() {
      this.$store.dispatch("walletToggle");
    },
  },
  computed: {
    isWalletExpanded() {
      return this.$store.state.layout.walletExpand;
    },
  },
  watch: {
    isWalletExpanded(val) {
     if(val){
        this.$refs["wallet-panel"].$el.classList.add("wallet-panel-expand");
     }
     else{
         this.$refs["wallet-panel"].$el.classList.remove("wallet-panel-expand");
     }
    },
  },
};
</script>
