<template>
  <div>
    <div class="wallet-wrapper">
      <div class="wallet-header">
        <button @click="closeWallet" class="wallet-hide-btn">
          <img
            src="@/assets/images/wallet/Hide.png"
            class="wallet-hide"
            alt="Hide"
            srcset=""
          />
        </button>

        <img
          src="@/assets/images/wallet/Bell.png"
          class="wallet-bell"
          alt="Bell"
          srcset=""
        />
        <div>
          <button
            type="button"
            class="btn btn-secondary"
            ref="wallet_button"
            v-on:click="walletHandler"
          >
            Connect to wallet
          </button>
        </div>
      </div>
      <div class="wallet">
        <div class="user-details">
          <img src="@/assets/images/navbar/Heart.png" alt="" srcset="" />
          <h5>{{ short_key }}</h5>
        </div>
        <div class="balance">
          <p>Your Balance <br />MVP</p>
          <div class="coin">{{ account_balance }}</div>
          <div class="fiat">$0</div>
          <button class="checkout">Checkout</button>
        </div>
      </div>
      <div class="recent-activity">
        <h5>Recent Activity</h5>
        <div class="activity-list">
          <div class="activity" v-for="item in data" :key="item.id">
            <!--  <img src="@/assets/images/navbar/Heart.png" alt="" srcset="" /> -->
            <div class="transaction-details">
              <div class="txHash">{{ item.txHash }}</div>
              <div class="transaction-time">
                {{ moment(item.blockTime).format("LLL") }}
              </div>
            </div>
          </div>
          <button>Show more</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { db } from "../../plugins/firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
} from "firebase/firestore";
//import solanaWeb3 from "@solana/web3.js";

document.body.onclick = function (e) {
  e = window.event ? event.srcElement : e.target;
  if (e.className && e.className.indexOf("txHash") != -1)
    alert(document.querySelectorAll(".txHash")[0].innerText);
};
export default {
  data() {
    return {
      data: [],
      publicKey: null,
      short_key: null,
      account_balance: 0,
    };
  },
  methods: {
    closeWallet() {
      this.$store.dispatch("walletToggle");
    },
    async walletHandler() {
      try {
        const resp = await window.solana.connect();
        this.publicKey = resp.publicKey.toString();
        //database.collection("PublicKey").add({ public_key: this.publicKey });

        const keyRef = collection(db, "PublicKey");
        const keyQuery = query(
          keyRef,
          where("public_key", "==", this.publicKey)
        );
        const querySnapshot = await getDocs(keyQuery);
        var flag = 0;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data().public_key);

          console.log("Key from firebase " + doc.data().public_key);
          console.log(this.publicKey);
          if (doc.data().public_key == this.publicKey) {
            flag = 1;
          }
        });

        if (flag === 1) {
          console.log("Key already exist in firebase");
        } else {
          console.log("Saving key to firebase");

          const docRef = await addDoc(collection(db, "PublicKey"), {
            public_key: this.publicKey,
          });
          console.log("Document written with ID: ", docRef.id);
        }

        this.$store.dispatch("setPublicAddress", this.publicKey);
        //making the public key short
        const short_key =
          this.publicKey.substring(0, 3) +
          "..." +
          this.publicKey.substr(this.publicKey.length - 5);
        //setting text as short private key
        this.$refs.wallet_button.innerText = short_key;
        this.short_key = short_key;

        this.getTokenBalance();
        if (this.data.length != 0) {
          this.data = [];
        } else {
          this.getRecentActivities();
        }
      } catch (err) {
        // { code: 4001, message: 'User rejected the request.' }
        console.log(err);
      }

      console.log(window.solana.isConnected);
    },
    async getTokenBalance() {
      const data = JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "getTokenAccountsByOwner",
        params: [
          this.publicKey,
          {
            mint: "hXjqR2vH2x1SbXRkF4GuV5uxBHnPRuUvTNZdDUYFwSp",
          },
          {
            encoding: "jsonParsed",
          },
        ],
      });
      await axios
        .post("https://api.devnet.solana.com", data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          const token_data = JSON.stringify(
            response.data.result.value,
            undefined,
            2
          );
          // console.log(token_data);
          const json = JSON.parse(token_data, undefined, 2);

          this.account_balance =
            json[0].account.data.parsed.info.tokenAmount["uiAmount"];
        })
        .catch((error) => console.log(error));
    },
    async getRecentActivities() {
      const data = JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "getSignaturesForAddress",
        params: [
          this.publicKey,
          {
            limit: 5,
          },
        ],
      });
      await axios
        // .get("https://public-api.solscan.io/account/transactions?account="+this.publicKey+"&limit=50")
        // .get(
        //   "https://public-api.solscan.io/account/transactions?account=6xHgex3yA7ZV26a6DpaXmK4t5a7MDcVt4Cic4ioHq6e3&limit=5"
        // )
        .post("https://api.devnet.solana.com", data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log(response.data.result);
          console.log(response.data.result.length);
          for (let i = 0; i < response.data.result.length; i++) {
            this.data.push({
              id: i,
              txHash:
                response.data.result[i].signature.substr(0, 10) +
                "........" +
                response.data.result[i].signature.substr(
                  response.data.result[i].signature.length - 10
                ),
              blockTime: response.data.result[i].blockTime,
            });
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style></style>
