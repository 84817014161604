import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/vue-formulate";
import "./plugins/moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/firebase"
import {firestorePlugin} from 'vuefire';

// Importing Global Styling
import "@/assets/css/styles.css";

Vue.config.productionTip = false;
Vue.use(firestorePlugin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
