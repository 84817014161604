<template>
  <div class="sidebar-nav">
    <div class="sidebar-top-nav">
      <router-link to="/"
        ><img src="@/assets/images/navbar/Logo.png" alt="Logo" srcset=""
      /></router-link>
      <router-link to="/mint"
        ><img src="@/assets/images/navbar/Explore.png" alt="Explore" srcset=""
      /></router-link>
      <router-link to=""
        ><img src="@/assets/images/navbar/Heart.png" alt="Heart" srcset=""
      /></router-link>
      <router-link to=""
        ><img src="@/assets/images/navbar/Setting.png" alt="Setting" srcset=""
      /></router-link>
      <router-link to=""
        ><img
          src="@/assets/images/navbar/Statistics.png"
          alt="Statistics"
          srcset=""
      /></router-link>
      <router-link to=""
        ><img src="@/assets/images/navbar/Wallet.png" alt="Wallet" srcset=""
      /></router-link>
    </div>
    <div>
      <router-link to="/">H</router-link>
      <router-link to="/about">A</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
