<template>
  <div class="main-wrapper">
    <SideBar class="side-bar" />
    <div class="main-body">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/layout/SideBar";
export default {
  components: {
    SideBar,
  },
};
</script>

<style>
.main-wrapper {
  display: flex;
  height: 100%;
}
.side-bar {
  display: inline-block;
  width: 72px;
  height: 100%;
  background-color: #212121;
  box-shadow: 4px -1px 3px 0px rgb(14 13 13 / 68%);
  -webkit-box-shadow: 4px -1px 3px 0px rgb(14 13 13 / 68%);
  -moz-box-shadow: 4px -1px 3px 0px rgb(14 13 13 / 68%);
}
.main-body {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-top: 42px;
  
}
</style>
