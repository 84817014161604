export const wallet = {
  state: {
    publicAddress: null,
  },
  mutations: {
    SET_PUBLIC_ADDRESS(state, address) {
      state.publicAddress = address;
    },
  },
  actions: {
    setPublicAddress({commit},address) {
      commit("SET_PUBLIC_ADDRESS", address);
    },
  },
  getters: {
  },
};
