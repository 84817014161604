<template>
  <MainLayout>
    <div class="about">
      <h1>Welcome to NFT Market Place</h1>
      <div class="nft-wrapper">
        <div class="row">
          <div class="nfts" v-for="item in images" :key="item.id">
            <div class="col-md-4">
              <div class="thumbnail">
                <img v-bind:src="item.image" alt="" srcset="" />
                <div class="name">{{ item.name }}</div>
                <!-- <div class="description">{{ item.description }}</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/views/layout/MainLayout.vue";
import {
  resolveToWalletAddress,
  getParsedNftAccountsByOwner,
} from "@nfteyez/sol-rayz";
import axios from "axios";
import { Connection } from "@solana/web3.js";
import { db } from "../../plugins/firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
} from "firebase/firestore";
export default {
  created() {
    this.getData();
  },
  components: {
    MainLayout,
  },
  data() {
    return {
      images: [],
    };
  },
  methods: {
    async getData() {
      try {
        var pub_keys = [];
        var address;
        const querySnapshot = await getDocs(collection(db, "PublicKey"));
        querySnapshot.forEach((doc) => {
          var pub_key = {};
          pub_key.public_key = doc.data();
          pub_keys.push(pub_key.public_key);
          //console.log(doc.id, " => ", doc.data());
        });
        console.log(pub_keys[1].public_key);

        for (var a = 0; a < pub_keys.length; a++) {
          address = pub_keys[a].public_key.toString();

          const connection = new Connection("https://api.devnet.solana.com/");
          const publicAddress = await resolveToWalletAddress({
            text: address,
          });

          var nftArray = await getParsedNftAccountsByOwner({
            publicAddress,
            connection,
          });

          for (var i = 0; i < nftArray.length; i++) {
            var uri = nftArray[i].data["uri"];
            //console.log(uri);
            await axios
              .get(uri)
              .then((response) => {
                console.log(response.data);

                this.images.push({
                  id: i,
                  image: response.data["image"],
                  name: response.data["name"],
                  description: response.data["description"],
                });
              })
              .catch((error) => console.error(error));
          }
        }
      } catch (error) {
        console.log("Error thrown, while fetching NFTs", error.message);
      }
    },
  },
};
</script>

<style scoped>
.form {
  max-width: 520px;
  text-align: left;
  margin: 20px;
}

img {
  object-fit: contain;
  height: auto;
  width: 240%;
  margin: 20px;
}
.nfts {
  text-align: center;
  max-width: 280px;
}
.nf-wrapper {
  display: flex;
}
</style>
