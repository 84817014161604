export const layout = {
  state: {
    walletExpand: false,
  },
  mutations: {
    WALLET_TOGGLE(state) {
      state.walletExpand = !state.walletExpand;
    },
  },
  actions: {
    walletToggle({commit}) {
      commit("WALLET_TOGGLE");
    },
  },
  getters: {
    isWalletExpanded: (state) => {
      return state.walletExpand;
    },
  },
};
